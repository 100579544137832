import React from "react";
import Layout from "../../components/Layout";
import RightColumn from "../../components/RightColumn";

export default class ResearchServicesPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo09.jpg" sidebarImageAlt="Research Services" metaTitle="Research Services">
        <div id="mainContent">
          <div class="header-one">Research Services</div>
          <p>
            The <strong>WBA Analytical Laboratories</strong>
            <strong>&trade;</strong> research team provides full-scale research
            services for live production, processing, further processing,
            sanitation, and food service applications.
          </p>
          <p>
            Our research professionals can work with your organization to design
            a research plan, determine&nbsp;sampling protocols, and provide
            testing services. Final reports include a detailed description of
            the project, and accompanying data is provided in an organized
            format ready for your organization to analyze.
          </p>
          <ul>
            <li>Method Evaluations </li>
            <li>
              Process Intervention Studies for raw and fully cooked beef, pork,
              and poultry{" "}
            </li>
            <li>
              Ready-to-Eat (RTE) Product Validations and Challenge Studies{" "}
            </li>
            <li>Thermal Death Time Studies (TDT) </li>
            <li>DNA Fingerprinting (PFGE) </li>
            <li>Biomapping of Microorganisms from the field to processing </li>
            <li>Controlled inoculation studies </li>
          </ul>
          <p>
            <a href="/contact-us">Contact Us</a>&nbsp;to discuss your research
            needs and pricing.&nbsp; Research projects are available with a
            complete statistical analysis upon request.
          </p>
        </div>

        <RightColumn />
      </Layout>
    );
  }
}
